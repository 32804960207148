import _ from 'lodash'

import {
  ExportSchema,
  IExportSchema,
} from '../../services/apiClients/AthenaClient'

//#region Utility Functions
function mkExportSchema(label: string, propertyName: string, type: string) {
  return new ExportSchema({ label, propertyName, type })
}

function replaceProperty(
  properties: IExportSchema[],
  labels: Record<string, boolean>,
  originalPropertyName: string,
  newLabel: string,
  newPropertyName: string,
  newType: string,
): void {
  const foundIndex = properties.findIndex(
    (property) => property.propertyName === originalPropertyName,
  )
  if (foundIndex !== -1)
    properties.splice(
      foundIndex,
      1,
      new ExportSchema({
        label: newLabel,
        propertyName: newPropertyName,
        type: newType,
      }),
    )
  labels[newLabel] = true
}

function replaceSchoolProperty(properties: IExportSchema[],
  labels: Record<string, boolean>): void {
    const foundIndex = properties.findIndex(
      (property) => property.propertyName === 'schools',
    )

    if(foundIndex !== -1)
    properties.splice(
    foundIndex,
    1,
    new ExportSchema({
      label: "PersonDegrees",
      type: "array",
      propertyName: "degrees",
      items: new ExportSchema({
        label: "Degree",
        type: "object",
        properties: [
          new ExportSchema({
            label: "School",
            type: "string",
            propertyName: "school",
          })
        ]
      })
    }),
  )
  labels['Schools'] = true
  }

//#endregion

export function remapSchemaForServer(
  originalSchema: ExportSchema,
  forProject?: boolean,
) {
  const schema = _.cloneDeep(originalSchema)
  const labels: Record<string, boolean> = {} // Dictionary for storing labels and avoiding duplicates

  // Preload existing labels from the originalSchema into labels
  if (
    originalSchema &&
    originalSchema.items &&
    originalSchema.items.properties
  ) {
    originalSchema.items.properties.forEach((property) => {
      labels[property.label as string] = true
    })
  }

  if (schema && schema.items && schema.items.properties) {
    const properties: Array<IExportSchema> = schema.items.properties

    // find index for hpExperience
    const xpIdx = properties.findIndex(
      (property) => property.propertyName === 'hpExperience',
    )
    if (xpIdx > -1) {
      properties.splice(xpIdx, 1)
      const exsHireDate = mkExportSchema('HireDate', 'hireDate', 'date')
      const exsTerminationDate = mkExportSchema(
        'TerminationDate',
        'terminationDate',
        'date',
      )
      let hdIdx = properties.findIndex(
        (property) => property.propertyName === 'hireDate',
      )
      let newIdx = 0
      if (hdIdx === -1) {
        // then need to add Hire Date
        newIdx = xpIdx - 1
        properties.splice(newIdx, 0, exsHireDate)
      }
      hdIdx = properties.findIndex(
        (property) => property.propertyName === 'hireDate',
      )

      const tdIdx = properties.findIndex(
        (property) => property.propertyName === 'terminationDate',
      )
      //console.log('pre-final adjustment', {hdIdx, tdIdx, properties});
      if (tdIdx === -1) {
        // then need to add Termination Date
        properties.splice(hdIdx + 1, 0, exsTerminationDate)
      }
    }
    //console.log('remap after hpExperience adjustments', properties);

    // find index for location
    const locationIdx = properties.findIndex(
      (property) => property.propertyName === 'location',
    )
    if (locationIdx !== -1) {
      console.log(
        '!@# location found and being split into server relevant info',
      )
      const newLabels =
        forProject === true
          ? ['StreetAddress', 'City', 'State', 'Zip']
          : ['City', 'State', 'Zip']

      const schemasToAdd = newLabels
        .filter((label) => !(label in labels))
        .map(
          (label) =>
            new ExportSchema({
              label,
              propertyName: _.camelCase(label),
              type: 'string',
            }),
        )

      if (schemasToAdd.length > 0) {
        properties.splice(locationIdx, 1, ...schemasToAdd)
        schemasToAdd.forEach(
          (schema) => (labels[schema.label as string] = true),
        )
      }
    }

    replaceProperty(
      properties,
      labels,
      'districts',
      'Region',
      'district',
      'string',
    )

    replaceProperty(
      properties,
      labels,
      'verticalMarkets',
      'VerticalMarkets',
      'verticalMarketsJoined',
      'string',
    )

    replaceProperty(
      properties,
      labels,
      'clientTypes',
      'ClientTypes',
      'clientTypesJoined',
      'string',
    )

    replaceProperty(
      properties,
      labels,
      'contractTypes',
      'ContractTypes',
      'contractTypesJoined',
      'string',
    )

    replaceProperty(
      properties,
      labels,
      'deliveryMethods',
      'DeliveryMethods',
      'deliveryMethodsJoined',
      'string',
    )

    replaceProperty(
      properties,
      labels,
      'procurementMethods',
      'ProcurementMethods',
      'procurementMethodsJoined',
      'string',
    )

    replaceProperty(
      properties,
      labels,
      'secondaryCategories',
      'SecondaryCategories',
      'secondaryCategoriesJoined',
      'string',
    )

    replaceProperty(
      properties,
      labels,
      'constructionTypes',
      'ConstructionTypes',
      'constructionTypesJoined',
      'string',
    )

    replaceProperty(
      properties,
      labels,
      'stakeholders',
      'Stakeholders',
      'stakeholdersJoined',
      'string',
    )

    replaceProperty(
      properties,
      labels,
      'accreditations',
      'Accreditations',
      'accreditationsJoined',
      'string',
    )

    replaceSchoolProperty(properties, labels)



    // todo: (perhaps) These are not in the old DASH or new but are available with some work (backend)
    // I recommend waiting for the backend to eb rewritten before this #mjl
    // replaceProperty(properties, labels, 'constructionTypes',
    //     'Construction Types', 'constructionTypesJoined', 'string');
    // replaceProperty(properties, labels, 'secondaryCategories',
    //     'Secondary Categories', 'secondaryCategoriesJoined', 'string');

    return schema
  }
}
