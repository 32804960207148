import React, {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState} from "react";
import AthenaClient, {IApiProject} from "services/apiClients/AthenaClient";
import {useSharedLargeCardStyles} from "../../shared/sharedStyles";
import {Dialog} from "@material-ui/core";

import DashDetailsField from "components/DashDetailsField";
import {getNamedData} from "utils/forms";
import {DashItemProgress} from "../../../dashCore/DashItemProgress";
import useAthenaClient from "hooks/useAthenaClient";
import useSaveCount from "hooks/useSaveCount";
import DashDivider from "components/DashDivider";
import {IWithDashSectionMethods, IWithDashSectionProps, withDashSection} from "../../shared/withDashSection";
import {formatInfotip, projectInfotips} from "constants/metadata/infotips";
import {v4 as uuidv4} from 'uuid';
import {commasFmt, currencyFmt} from "utils/numberToStringUtil";
import PassThru from "patterns/PassThru";
import {useGlobalState} from "app/state/useGlobalState";
import CoreDebug from "../../../../features/core/CoreDebug";
import globalState from "../../../../app/state/GlobalState";
import {useObserver} from "mobx-react";
// end imports

const SECTION_ID = 'projectDetailsOverviewSection';

const ProjectDetailsOverviewSection = forwardRef<IWithDashSectionMethods, IWithDashSectionProps & { onSave?: () => void }>(
    (props, forwardRef: ForwardedRef<IWithDashSectionMethods>) => {
      const classes = useSharedLargeCardStyles();
      const gs = useGlobalState();
      const [model, setModel] = useState<IApiProject>(props.model);
      const [saveCount, saved] = useSaveCount();
      const {athenaClient} = useAthenaClient();
      const [showInfotipModal, setShowInfotipModal] = useState(false);
      const [content, setContent] = useState('');
      const [sectionKey, setSectionKey] = useState(uuidv4());
      const [canEditContractValues, setCanEditContractValues] = useState(model.autoUpdateContractValue);

      useEffect(() => {
        const el = document.body;  // Replace this with your targeted element.
        const checkboxes = el.querySelectorAll('input[type="checkbox"]');

        checkboxes.forEach((checkbox: Element) => {
          const inputCheckbox = checkbox as HTMLInputElement;
          inputCheckbox.dataset.checked = inputCheckbox.checked ? "true" : "false";
        });
      }, []);

      if (model === null) {
        setModel(props.model);
      }

      useEffect(() => {
        async function reload() {
          if (athenaClient && model && model.id) {
            const m = await athenaClient.getProject(model.id);
            setModel(m);
            console.log('$$$@ Project Overview reload', {startDate:model.startDate, completionDate: model.completionDate,
            ticks: globalState.projectDetailTicks});
          }
        }
        reload();
      }, [globalState.projectDetailTicks]);

      // v2
      useImperativeHandle(forwardRef, () => ({
        save: async () => {
          function transform(data: {originalConstructionContractValue:string|number,cost:string|number}|unknown) {
            const tmpData = data as {originalConstructionContractValue:string|number,cost:string|number};
            tmpData.originalConstructionContractValue = parseInt(tmpData.originalConstructionContractValue + '');
            tmpData.cost = parseInt(tmpData.cost + '');
            return tmpData;
          }

          console.log('begin ' + SECTION_ID + ' save');
          const elem = document.querySelector('#' + SECTION_ID);
          const data = transform(getNamedData(elem));
          console.log('namedData was:', data);
          if ('_' in data)
            return false;
          try {
            const client = (athenaClient as AthenaClient);
            const m = await client.patchProject(data);
            setModel(m as IApiProject);
            gs.setCurrentProject(m as IApiProject);
            props.setEditMode(false);
            setSectionKey(uuidv4());
            return true;
          } catch (err) {
            console.log(`Error during save (patch entity - ${SECTION_ID}) : `, err);
            return false;
          }
        }
      }));
      // end useImperativeHandle

      const handleInfotipClick = (key: string) => {
        const infotip = projectInfotips[key];
        setContent(formatInfotip(infotip));
        setShowInfotipModal(true);
      }

      if (!props.model) {
        return <DashItemProgress/>;
      }

      return useObserver(() =>
          <React.Fragment key={sectionKey + '-' + globalState.projectDetailTicks.toString()}>
            {/* id */}
            <DashDetailsField mode="hidden"
                              label="Id"
                              showLabel={false}
                              editMode={props.editMode}
                              name="id"
                              defaultValue={model.id}
            />

            {/* projectNumbersCopy - pre-existing project numbers */}
            <DashDetailsField mode="hidden"
                              label="Copy of Project Numbers"
                              showLabel={false}
                              editMode={props.editMode}
                              name="projectNumbersCopy"
                              defaultValue={JSON.stringify(model.projectNumbers ?? [])}
            />

            {/* projectNumbers */}
            <DashDetailsField
                mode="multiAuto"
                label="Project Number"
                showLabel={true}
                infotipKey="projectNumbers"
                width="47%"
                editMode={props.editMode}
                name="projectNumbers[s]"
                values={model.projectNumbers}
                hiddenInputValueFormat={'json'}
                validator={checkProjectNumbers}
            />
            {/*// choices={props.referenceData.options['']}*/}


            {/* name */}
            <DashDetailsField
                mode="text"
                label="Project Name"
                showLabel={true}
                infotipKey="name"

                width="47%"
                editMode={props.editMode}
                name="name"
                defaultValue={model.name}
            />

            {/* squareFootage */}
            <DashDetailsField
                mode="number"
                label="Square Footage"
                showLabel={true}
                infotipKey="squareFootage"
                width="47%"
                editMode={props.editMode}
                name="squareFootage"
                defaultValue={model.squareFootage}
                formatFn={commasFmt.format}
            />

            {/* verticalMarket */}
            <DashDetailsField
                mode="select"
                label="Vertical Market"
                showLabel={true}
                infotipKey="verticalMarket"
                width="47%"
                editMode={props.editMode}
                name="verticalMarket"
                defaultValue={model.verticalMarket}
                choices={PassThru.log('!@@@! verticalMarkets', props.referenceData.options['verticalMarkets'])}
            />

            {/* clientType */}
            <DashDetailsField
                mode="select"
                label="Sector"
                showLabel={true}
                infotipKey="clientType"
                width="47%"
                editMode={props.editMode}
                name="clientType"
                defaultValue={model.clientType}
                choices={props.referenceData.options['sectors']}
            />

            {/* districts */}
            <DashDetailsField
                mode="multi"
                label="Region"
                showLabel={true}
                infotipKey="region"
                width="47%"
                editMode={props.editMode}
                name="districts[s]"
                hiddenInputValueFormat={'json'}
                values={model.districts}
                choices={props.referenceData.options['regionWithCodes']}
            />

            {/* awardDate */}
            <DashDetailsField
                mode="date"
                label="Contract Award Date"
                showLabel={true}
                infotipKey="contractAwardDate"

                width="47%"
                editMode={props.editMode}
                name="awardDate"
                defaultValue={model.awardDate}
            />

            {/* startDate */}
            <DashDetailsField
                mode="date"
                label="Start Date"
                showLabel={true}
                infotipKey="startDate"
                width="47%"
                editMode={false}
                defaultValue={model.startDate}
            />

            {/* completionDate */}
            <DashDetailsField
                mode="date"
                label="Completion Date"
                showLabel={true}
                infotipKey="completionDate"
                width="47%"
                editMode={false}
                defaultValue={model.completionDate}
            />

            {/* contractType */}
            <DashDetailsField
                mode="select"
                label="Contract Type"
                showLabel={true}
                infotipKey="contractType"
                width="47%"
                editMode={props.editMode}
                name="contractType"
                defaultValue={model.contractType}
                choices={PassThru.log('!@@@! contracts', props.referenceData.options['contractTypes'])}
            />

            {/* originalConstructionContractValue */}
            <DashDetailsField
                mode="number"
                label="Original Contract Value"
                showLabel={true}
                infotipKey="originalConstructionContractValue"
                width="47%"
                editMode={props.editMode}
                name="originalConstructionContractValue"
                defaultValue={model.originalConstructionContractValue}
                formatFn={currencyFmt.format}
            />

            {/* cost */}
            <DashDetailsField
                mode="number"
                label="Final Contract Value"
                showLabel={true}
                infotipKey="cost"
                width="47%"
                editMode={canEditContractValues ? false : props.editMode }
                name="cost"
                defaultValue={model.cost}
                formatFn={currencyFmt.format}
            />

            {/* deliveryMethod */}
            <DashDetailsField
                mode="select"
                label="Delivery Method"
                showLabel={true}
                width="47%"
                editMode={props.editMode}
                name="deliveryMethod"
                defaultValue={model.deliveryMethod}
                choices={props.referenceData.options['deliveryMethods']}
            />

            {/* procurementMethod */}
            <DashDetailsField
                mode="select"
                label="Procurement Method"
                showLabel={true}
                width="47%"
                editMode={props.editMode}
                name="procurementMethod"
                defaultValue={model.procurementMethod}
                choices={props.referenceData.options['procurementMethods']}
            />

            {/* nonHenselPhelpsProject */}
            <DashDetailsField
                mode="checkSwitchNC"
                label="Non-Hensel Phelps Project"
                showLabel={true}
                infotipKey="nonHenselPhelpsProject"
                width="47%"
                editMode={false}
                defaultValue={CoreDebug.passThru('$$$@ nonHPP', model.nonHenselPhelpsProject)}
            />

            {/* usable */}
            <DashDetailsField
                mode="checkSwitch"
                label="Usable"
                showLabel={true}
                infotipKey="usable"
                width="47%"
                editMode={props.editMode}
                name="usable"
                value={model.usable}
            />

            {/* jointVenture */}
            <DashDetailsField
                mode="checkSwitch"
                label="Joint Venture"
                showLabel={true}
                infotipKey="jointVenture"
                width="47%"
                editMode={props.editMode}
                name="jointVenture"
                value={model.jointVenture}
            />

            {/* Auto Update Contract Value */}
            <DashDetailsField
                mode="checkSwitch"
                label="Auto Update Contract Value"
                showLabel={true}
                infotipKey="autoUpdateContractValue"
                width="47%"
                editMode={props.editMode}
                name="autoUpdateContractValue"
                value={model.autoUpdateContractValue}
                setCanEditContractValues={setCanEditContractValues}
            />

            <DashDivider width="80vw" height="5px"/>

          </React.Fragment>
      );
    });

function checkProjectNumbers(projectNumbersJsonArr: string): boolean {
  const el = document.querySelector('input[name=projectNumbersCopy]');
  const projectNumbersCopyJsonArr = (el as HTMLInputElement).value;
  const projectNumbersCopy = JSON.parse(projectNumbersCopyJsonArr);
  const projectNumbers = JSON.parse(projectNumbersJsonArr);

  const pattern = /^(\d{7}|\d{2}-\d{2}-\d{3})$/;

  for (const projectNum of projectNumbers) {
    if (!pattern.test(projectNum) && !projectNumbersCopy.includes(projectNum)) {
      const errorMessage = `Project number ${projectNum} does not match the rule for seven digit project numbers. It should be 7 digits in the form xxxxxxx or xx-xx-xxx.`;
      alert(errorMessage);
      return false;
    }
  }
  return true;
}


export default withDashSection('Overview', SECTION_ID, ProjectDetailsOverviewSection);
