import React, { useContext, useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'

import { makeStyles } from '@material-ui/core/styles'
import {
  IconButton,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import ListAltIcon from '@material-ui/icons/ListAlt'

import { normalizeChoices, normalizeValue } from './dashDetailFieldUtil'
import DashChipList from 'components/dashCore/DashChipList'
import { DateTime } from 'luxon'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { GreenCheck } from '../dashCore/GreenCheck'
import { RedX } from '../dashCore/RedX'
import DashRichText from '../dashCore/DashRichText'
import { AddCircle } from '@material-ui/icons'
import DashDetailsDate from './DashDetailsDate'
import ChangeFragment from 'features/coreComponents/ChangeFragment'
import _ from 'lodash'
import DashApproval from '../dashCore/DashApproval'
import { InfotipContext } from '../../providers/InfotipProvider'
import CoreDebug from '../../features/core/CoreDebug'

const useStyles = makeStyles(() => ({
  displayNone: {
    display: 'none',
    visibility: 'hidden',
  },
  allArea: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: '1px solid #666',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  labelArea: {
    flex: '0 0 auto',
    textAlign: 'left',
    height: '56px',
    padding: '10px 10px',
    fontSize: '1rem',
    fontWeight: 700,
    boxSizing: 'border-box',
    alignSelf: 'flex-start',
  },
  label: {
    height: '40%',
  },
  inputArea: {
    flex: '1',
    border: '0 solid rgba(0,0,255,0.1)',
    padding: '4px 10px',
    boxSizing: 'border-box',
    textAlign: 'right',
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },
  },
  singleArea: {
    flexBasis: '100%',
    flexGrowth: 1,
    flexShrink: 0,
    border: '0 solid #e7e7e7',
    padding: '4px 10px',
    textAlign: 'left',
  },
  textField: {
    width: '100%',
    '& .MuiInput-input': {
      color: 'black',
      textAlign: 'right',
    },
  },
  textFieldEdit: {
    width: '100%',
    '& .MuiInput-input': {
      color: 'black',
      textAlign: 'right',
      border: '1px solid #999',
    },
  },
  padInfoInfo: {
    paddingLeft: '10px',
  },
}))

function getVisualBoolean(value: boolean) {
  console.log('$$$@ inside get VisualBoolean', value)
  return value ? <GreenCheck /> : <RedX />
}

export interface IDashDetailsFieldProps {
  mode:
    | 'span'
    | 'text'
    | 'textControlled'
    | 'textWrap'
    | 'select'
    | 'multi'
    | 'multiAuto'
    | 'multiAutoAsList'
    | 'number'
    | 'date'
    | 'check'
    | 'switch'
    | 'reference'
    | 'checkSwitch'
    | 'checkSwitchNC'
    | 'checkSwitch_v2'
    | 'hidden'
    | 'richText'
    | 'richText2'
    | 'approval'
    | 'subheading'
    | 'addButton'
    | 'signedReleaseCheck'
  width?: string
  label: string
  value?: string | string[] | number | boolean | Date | null
  values?: string[]
  defaultValue?: string | number | boolean | Date | null
  choices?: string[] | number[] | null
  off?: boolean
  name?: string
  //readOnly?: boolean,
  editMode?: boolean
  valueGraph?: any | undefined
  approvalKey?: string
  formatFn?: (value: any) => string
  muiTooltip?: string
  infotipKey?: string
  onValueChange?: (value: any) => void
  onValuesChange?: (values: string[]) => void
  infotipCallback?: (key: string) => void
  showLabel: boolean
  required?: boolean
  minLabelAreaWidth?: string
  validator?: (valueAsStr: string) => boolean
  simpleTooltip?: string
  hiddenInputValueFormat?: 'json' | 'csv'
  infoMode?: 'detailed' | 'minimal' | undefined
  setCanEditContractValues?: (canEdit: boolean) => void
  //children: never[];
}

export default function DashDetailsField(props: IDashDetailsFieldProps) {
  const classes = useStyles()
  const { closeInfotip, openInfotip } = useContext(InfotipContext)

  const minLabelAreaWidth =
    props.minLabelAreaWidth || props.minLabelAreaWidth === '0'
      ? props.minLabelAreaWidth
      : props.label
      ? '200px'
      : '10px'

  const coreElementWidth = props.width ?? '100%'

  let InputProps = {}
  InputProps = { ...InputProps, disableUnderline: true }
  const initValue = normalizeValue(props.value)

  const [value, setValue] = useState<
    string | string[] | number | boolean | Date | DateTime | undefined | null
  >(initValue)
  const [values, setValues] = useState<string[]>()
  const updatedEditModeRef = useRef(props.editMode)
  const validatorRef = useRef<HTMLInputElement>(null)
  //const tmpDateValue = props.value ?? props.defaultValue;
  const tmpDateValue =
    props.value instanceof Date
      ? props.value
      : props.defaultValue instanceof Date
      ? props.defaultValue
      : undefined
  const [dateValue, setDateValue] = useState<Date | undefined>(tmpDateValue)

  const defaultValue = normalizeValue(props.defaultValue)
  const choices = normalizeChoices(props.choices)

  // special conditional InputProps flags
  if (!props.editMode) InputProps = { ...InputProps, readOnly: true }
  if (props.name) InputProps = { ...InputProps, name: props.name }
  if (props.mode === 'number') InputProps = { ...InputProps, type: 'number' }

  useEffect(() => {
    setValue(initValue)
    if (props.mode === 'date') {
      setDateValue(initValue as Date)
    }
  }, [initValue])

  useEffect(() => {
    if (props.editMode === false) {
      setValue(initValue)
    }
  }, [props.editMode])

  useEffect(() => {
    if (validatorRef.current && props.validator) {
      const el = (validatorRef.current as unknown) as Record<string, unknown>
      el['validate'] = props.validator
    }
  }, [props.validator, validatorRef.current])

  const onChipListChange = (values: string[]) => {
    console.log('onChipListChange', values)
    if (props.onValuesChange) {
      console.log('props.onValuesChange', values)
      props.onValuesChange(values)
    }
    setValues(values)
  }

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log('!@# onSwitchChange', event.target.checked, event.target);
    //event.target.dataset['checked'] = String(event.target.checked);
    //console.log('!@#8 from inside DashDetailField switchCheck mode', event.target.checked);
    if(props.setCanEditContractValues){
      props.setCanEditContractValues(event.target.checked);
    }
    setValue(!!event.target.checked)

    if (props.onValueChange) {
      props.onValueChange(!!event.target.checked)
    }
  }

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (!date) {
      setValue(null)
    } else {
      const isoDateStr = date.toISODate()
      setValue(isoDateStr)
      setDateValue(new Date(isoDateStr))
    }
  }

  // selects - guard against an old choice that is no longer available
  if (props.mode === 'select') {
    if (choices && choices.length > 0 && props.defaultValue) {
      // todo: partially fixed - moving forward need better non-available option
      if (choices.includes(props.defaultValue.toString())) {
        _.noop()
        //console.log('!@# protection against old non-available choices in selects', props.name, props.choices);
        //updatedEditModeRef.current = false;
      }
    }
  }

  // end of hooks / begin rendering logic
  if (props.off) {
    return <></>
  }

  if (props.mode === 'reference' || props.mode === 'addButton') {
    const url = props.defaultValue
      ? props.defaultValue.toString()
      : props.value?.toString() ?? ''
    return (
      <>
        {/*<span>Url: {url}</span>*/}
        <div className={classes.singleArea}>
          {/*<Tooltip title={props?.muiTooltip ?? ''}>*/}
          <Tooltip title={props?.muiTooltip ?? url}>
            {props.mode === 'reference' ? (
              <IconButton onClick={() => window.open(url, '_blank')}>
                <ListAltIcon style={{ paddingRight: '6px' }} />
                <Typography>{props.label.toUpperCase()}</Typography>
              </IconButton>
            ) : (
              <IconButton onClick={eval(props.defaultValue as string)}>
                <AddCircle style={{ paddingRight: '6px' }} />
                <Typography>{props.label.toUpperCase()}</Typography>
              </IconButton>
            )}
          </Tooltip>
        </div>
      </>
    )
  }

  if (props.mode === 'hidden') {
    return (
      <input
        className={classes.displayNone}
        type="hidden"
        name={props.name}
        width={'100%'}
        value={props.defaultValue as string}
      />
    )
  }

  if (props.mode === 'subheading') {
    return (
      <div style={{ width: coreElementWidth }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'left',
            color: '#A6192E',
            borderBottom: '1px solid #ddd',
          }}
        >
          <h3>{props.defaultValue}</h3>
        </div>
      </div>
    )
  }

  if (props.mode === 'span') {
    return <span>{props.defaultValue}</span>
  }

  let infotipKey = props.infotipKey
  if (props.infotipKey === '_default' && props.name) {
    infotipKey = props.name
  }

  const coreElement = (
    <>
      <div style={{ flexBasis: '1', width: coreElementWidth }}>
        <div className={classes.allArea}>
          {props.showLabel ? (
            <div
              className={classes.labelArea}
              style={{ minWidth: minLabelAreaWidth }}
            >
              <div className={classes.label}>
                {props.label}
                {props.infotipCallback && infotipKey && (
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() =>
                      props.infotipCallback &&
                      props.infotipCallback(infotipKey as string)
                    }
                  >
                    <InfoIcon fontSize="small" />
                  </IconButton>
                )}
                {!props.infotipCallback && props.infotipKey && (
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => openInfotip(infotipKey ?? '')}
                  >
                    <InfoIcon fontSize="small" className="infotip-icon" />
                  </IconButton>
                )}
                {props.simpleTooltip && (
                  <Tooltip
                    title={props.simpleTooltip}
                    aria-label={props.simpleTooltip}
                  >
                    <InfoIcon
                      fontSize="large"
                      className={classes.padInfoInfo}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className={classes.inputArea}>
            {props.mode === 'text' ? (
              <TextField
                className={
                  props.editMode ? classes.textFieldEdit : classes.textField
                }
                InputLabelProps={{ shrink: false }}
                InputProps={InputProps}
                name={props.name}
                required={CoreDebug.passThru(
                  '!@#8 required inside',
                  props.required,
                )}
                defaultValue={props.defaultValue}
                value={value}
                onChange={(event) => setValue(event.target.value)}
              ></TextField>
            ) : (
              ''
            )}
            {/*{props.mode === 'textControlled' ? (*/}
            {/*    <TextControlled label={props.label}*/}
            {/*               name={props.name}*/}
            {/*               onValueChange={(e) => props.onValueChange && props.onValueChange(e.target.value)}*/}
            {/*               value={props.value} mode={props.mode} showLabel={props.showLabel}>*/}
            {/*    </TextControlled>*/}
            {/*): '' }*/}
            {props.mode === 'textWrap' ? (
              props.editMode ? (
                <TextField
                  className={
                    props.editMode ? classes.textFieldEdit : classes.textField
                  }
                  InputLabelProps={{ shrink: false }}
                  InputProps={InputProps}
                  name={props.name}
                  defaultValue={props.defaultValue}
                ></TextField>
              ) : (
                <div>
                  <span className="text-base inline-block">{defaultValue}</span>
                </div>
              )
            ) : (
              ''
            )}
            {props.mode === 'number' ? (
              props.editMode ? (
                <TextField
                  className={classes.textFieldEdit}
                  InputLabelProps={{ shrink: false }}
                  inputProps={{ step: '1' }}
                  InputProps={{ ...InputProps }}
                  defaultValue={Number(defaultValue)}
                />
              ) : (
                <span className={classes.textField}>
                  {!!props.formatFn && typeof props.defaultValue === 'number'
                    ? props.formatFn(props.defaultValue)
                    : defaultValue}
                </span>
              )
            ) : (
              ''
            )}
            {/*{props.mode === 'select' ? (props.editMode ?*/}
            {/*    <TextField className={props.values === undefined ? classes.textField : classes.textField}*/}
            {/*        InputLabelProps={{ shrink: false }}*/}
            {/*        InputProps={InputProps}*/}
            {/*        defaultValue={defaultValue}*/}
            {/*        select={choices && choices.length > 0}>*/}
            {/*        /!*onChange={(e: unknown) => console.log(e)}*!/*/}
            {/*        SelectProps={{onChange: (x:React.ChangeEvent<HTMLSelectElement>) => props.onValueChange && props.onValueChange(x.target.value)}}*/}
            {/*        {choices.map((choice) => (*/}
            {/*            <MenuItem key={choice} value={choice}>{choice}</MenuItem>*/}
            {/*        ))}*/}
            {/*    </TextField>:*/}
            {/*    <span>{defaultValue}</span>*/}
            {/*):  '' }*/}
            {/* todo: see todo above */}
            {/*{props.mode === 'select' ? (updatedEditModeRef.current ? */}
            {props.mode === 'select' ? (
              props.editMode ? (
                <TextField
                  className={
                    props.values === undefined
                      ? classes.textField
                      : classes.textField
                  }
                  InputLabelProps={{ shrink: false }}
                  InputProps={InputProps}
                  defaultValue={defaultValue}
                  select
                  onChange={props.onValueChange}
                >
                  {choices.map((choice) => (
                    <MenuItem key={choice} value={choice}>
                      {choice}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <span>{defaultValue}</span>
              )
            ) : (
              ''
            )}
            {props.mode === 'multi' ? (
              <DashChipList
                values={props.values}
                editMode={!!props.editMode}
                name={props.name}
                choices={choices}
                justifyChips="left"
                hiddenInputValueFormat={props.hiddenInputValueFormat}
                onChange={onChipListChange}
              />
            ) : (
              ''
            )}
            {props.mode === 'multiAuto' ? (
              <DashChipList
                values={props.values}
                editMode={!!props.editMode}
                autocomplete={true}
                name={props.name}
                choices={choices}
                justifyChips="left"
                hiddenInputValueFormat={props.hiddenInputValueFormat}
                onChange={onChipListChange}
              />
            ) : (
              ''
            )}
            {props.mode === 'switch' ? (
              <Switch
                checked={_.includes(['true', true], value)}
                onChange={onSwitchChange}
                disabled={props.editMode === false}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            ) : (
              ''
            )}
            {props.mode === 'check' ? (
              _.includes(['true', true], value) ? (
                <GreenCheck />
              ) : (
                <RedX />
              )
            ) : (
              ''
            )}
            {props.mode === 'signedReleaseCheck' ? (
              _.includes(['true', true], value) ? (
                <GreenCheck />
              ) : _.includes(['false', false], value) ? (
                <RedX />
              ) : (
                'Not Completed'
              )
            ) : (
              ''
            )}
            {props.mode === 'checkSwitch' ? (
              !props.editMode ? (
                <>{getVisualBoolean(_.includes(['true', true], value))}</>
              ) : (
                <>
                  <Switch
                    onChange={onSwitchChange}
                    color="primary"
                    checked={value === 'true' || value === true}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  {props.name && (
                    <input
                      type="hidden"
                      name={props.name}
                      data-value-type="boolean"
                      value={_.includes(['true', true], value).toString()}
                    />
                  )}
                </>
              )
            ) : (
              ''
            )}
            {props.mode === 'checkSwitchNC' ? (
              !props.editMode ? (
                <>
                  {getVisualBoolean(
                    (['true', true] as unknown[]).includes(defaultValue),
                  )}
                </>
              ) : (
                <>
                  <Switch
                    onChange={onSwitchChange}
                    color="primary"
                    checked={value === 'true' || value === true}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  {props.name && (
                    <input
                      type="hidden"
                      name={props.name}
                      data-value-type="boolean"
                      value={
                        (['true', true] as unknown[]).includes(defaultValue)
                          ? 'true'
                          : ''
                      }
                    />
                  )}
                </>
              )
            ) : (
              ''
            )}
            {props.mode === 'approval' ? (
              <DashApproval
                valueGraph={props.valueGraph}
                approvalKey={props.approvalKey ?? ''}
                infoMode={props.infoMode ?? undefined}
                editMode={props.editMode}
              />
            ) : (
              ''
            )}

            {props.mode === 'checkSwitch_v2' ? (
              !props.editMode ? (
                getVisualBoolean(_.includes(['true', true], value))
              ) : (
                <>
                  <Switch
                    onChange={onSwitchChange}
                    color="primary"
                    checked={value === 'true' || value === true}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  {props.name && (
                    <input
                      type="hidden"
                      name={props.name}
                      data-value-type="boolean"
                      value={_.includes(['true', true], value).toString()}
                    />
                  )}
                </>
              )
            ) : (
              ''
            )}
            {props.mode === 'richText2' ? (
              <section>
                <article>
                  <DashRichText
                    value={(value ?? '').toString()}
                    editMode={props.editMode}
                    onChange={(value) =>
                      props.onValueChange ? props.onValueChange(value) : null
                    }
                    name={props.name}
                  />
                </article>
              </section>
            ) : (
              ''
            )}
            {props.mode === 'richText' ? (
              <section>
                <article>
                  <DashRichText
                    value={(defaultValue ?? value ?? '').toString()}
                    editMode={props.editMode}
                    onChange={(value) =>
                      props.onValueChange ? props.onValueChange(value) : null
                    }
                    name={props.name}
                  />
                </article>
              </section>
            ) : (
              ''
            )}
            {props.mode === 'date' ? (
              <>
                <DashDetailsDate
                  value={value ?? defaultValue}
                  editMode={props.editMode}
                  name={props.name}
                />
              </>
            ) : null}
            {props.validator && props.name && (
              <input
                type="hidden"
                name={props.name + '[v]'}
                ref={validatorRef}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )

  return props.muiTooltip && !props.editMode ? (
    <Tooltip title={props.muiTooltip}>{coreElement}</Tooltip>
  ) : (
    <>{coreElement}</>
  )
}
